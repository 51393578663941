// ServicesSection.js
import React from "react"

function ServicesSection() {
    return (
        <section id="services" className="py-16 text-center px-4">
            <h2 className="text-3xl font-bold mb-10 text-white">
                Our Services
            </h2>
            <div className="flex flex-wrap justify-center">
                <div className="max-w-sm bg-white rounded-lg shadow-md m-4 p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-primary">
                        Development
                    </h3>
                    <p>
                        We build scalable, beautiful and functioning solutions
                        across various applications.
                    </p>
                </div>
                <div className="max-w-sm bg-white rounded-lg shadow-md m-4 p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-primary">
                        User Experience
                    </h3>
                    <p>
                        Our specialists in UX will ensure that your product
                        follows the best practices in user experience and offers
                        smooth interaction.
                    </p>
                </div>
                <div className="max-w-sm bg-white rounded-lg shadow-md m-4 p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-primary">
                        Technical Consulting
                    </h3>
                    <p>
                        Superior technical consultation with research &
                        development and support to help you realise your project
                        goals.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default ServicesSection
