// ContactUs.js
import React, { useState } from "react"

function ContactUs() {
    // State variables for form inputs
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        enquiryMessage: "",
        preferredContactMethod: "Email", // Default value
    })

    // State variables for form submission status
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submissionResult, setSubmissionResult] = useState(null)
    const [errors, setErrors] = useState({})

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target

        // For radio buttons, the value comes from the checked attribute
        if (e.target.type === "radio") {
            setFormData({ ...formData, preferredContactMethod: value })
        } else {
            setFormData({ ...formData, [name]: value })
        }

        // Clear errors when the user modifies the input
        setErrors({ ...errors, [name]: "" })
    }

    // E.164 validation function
    const validatePhoneNumber = (number) => {
      const e164Regex = /^\+[1-9]\d{1,14}$/;
      return e164Regex.test(number);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        setSubmissionResult(null)

        // Reset errors
        const newErrors = {}

        // check if + in phone number
        if (!formData.phoneNumber.includes('+')) {
            formData.phoneNumber = '+' + formData.phoneNumber;
        }

        // Validate phone number
        if (!validatePhoneNumber(formData.phoneNumber)) {
            newErrors.phoneNumber =
                "Please enter a valid phone number in international format. (E.164)"
        }

        // remove + from phone number
        formData.phoneNumber = formData.phoneNumber.replace('+', '');

        // Add other validations if needed (e.g., email format)

        // If there are validation errors, set errors state and stop submission
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            setIsSubmitting(false)
            return
        }

        // API endpoint URL
        const apiUrl =
            "https://19w1dn.api.infobip.com/forms/1/forms/d79beb14-8e69-41e9-9f37-0ff3c36f1d1a/data"

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "App d3f47dfe54933fb403f9068ca4b89473-1b5a650d-94f7-421c-a332-9cf678dcbf07",
                    Accept: "application/json",
                },
                body: JSON.stringify(formData),
            })

            if (response.ok) {
                // Handle successful submission
                setSubmissionResult("success")
                // Optionally reset the form
                setFormData({
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    enquiryMessage: "",
                    preferredContactMethod: "Email",
                })
                setErrors({})
            } else {
                // Handle server errors
                setSubmissionResult("error")
            }
        } catch (error) {
            // Handle network errors
            setSubmissionResult("error")
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <section
            id="contact"
            className="py-16 bg-gray-50 text-center px-4"
        >
            <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
            <p className="mb-10 text-lg">
                Ready to elevate your customer service experience?
            </p>
            <form
                onSubmit={handleSubmit}
                className="max-w-xl mx-auto space-y-6 text-left"
            >
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full">
                        <label
                            htmlFor="firstName"
                            className="block mb-1 font-semibold"
                        >
                            First Name
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            required
                            value={formData.firstName}
                            onChange={handleChange}
                            className={`w-full px-4 py-3 border ${
                                errors.firstName
                                    ? "border-red-500"
                                    : "border-gray-300"
                            } rounded-md focus:outline-none focus:ring-2 focus:ring-primary`}
                        />
                        {errors.firstName && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.firstName}
                            </p>
                        )}
                    </div>
                    <div className="w-full mt-6 md:mt-0">
                        <label
                            htmlFor="lastName"
                            className="block mb-1 font-semibold"
                        >
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            required
                            value={formData.lastName}
                            onChange={handleChange}
                            className={`w-full px-4 py-3 border ${
                                errors.lastName
                                    ? "border-red-500"
                                    : "border-gray-300"
                            } rounded-md focus:outline-none focus:ring-2 focus:ring-primary`}
                        />
                        {errors.lastName && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors.lastName}
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="phoneNumber"
                        className="block mb-1 font-semibold"
                    >
                        Phone Number (International format)
                    </label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="+27110001234"
                        required
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 border ${
                            errors.phoneNumber
                                ? "border-red-500"
                                : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-primary`}
                    />
                    {errors.phoneNumber && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors.phoneNumber}
                        </p>
                    )}
                </div>
                <div>
                    <label htmlFor="email" className="block mb-1 font-semibold">
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 border ${
                            errors.email ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-primary`}
                    />
                    {errors.email && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors.email}
                        </p>
                    )}
                </div>
                <div>
                    <label
                        htmlFor="enquiryMessage"
                        className="block mb-1 font-semibold"
                    >
                        Message
                    </label>
                    <input
                        type="enquiryMessage"
                        name="enquiryMessage"
                        id="enquiryMessage"
                        placeholder="Hi, I'd like to learn more about your services."
                        required
                        value={formData.enquiryMessage}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 border ${
                            errors.enquiryMessage
                                ? "border-red-500"
                                : "border-gray-300"
                        } rounded-md focus:outline-none focus:ring-2 focus:ring-primary`}
                    />
                    {errors.email && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors.email}
                        </p>
                    )}
                </div>
                <div>
                    <p className="block mb-2 font-semibold">
                        Preferred Method of Contact
                    </p>
                    <div className="flex items-center space-x-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="preferredContactMethod"
                                value="Email"
                                checked={
                                    formData.preferredContactMethod === "Email"
                                }
                                onChange={handleChange}
                                className="form-radio text-primary"
                            />
                            <span className="ml-2">Email</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="preferredContactMethod"
                                value="WhatsApp"
                                checked={
                                    formData.preferredContactMethod ===
                                    "WhatsApp"
                                }
                                onChange={handleChange}
                                className="form-radio text-primary"
                            />
                            <span className="ml-2">WhatsApp</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="preferredContactMethod"
                                value="Phone"
                                checked={
                                    formData.preferredContactMethod === "Phone"
                                }
                                onChange={handleChange}
                                className="form-radio text-primary"
                            />
                            <span className="ml-2">Phone</span>
                        </label>
                    </div>
                </div>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-primary hover:bg-orange-600 text-white font-semibold py-3 rounded-md transition duration-300"
                >
                    {isSubmitting ? "Submitting..." : "Submit"}
                </button>
            </form>

            {/* Display submission result */}
            {submissionResult === "success" && (
                <p className="mt-6 text-green-600">
                    Thank you! Your message has been sent.
                </p>
            )}
            {submissionResult === "error" && (
                <p className="mt-6 text-red-600">
                    Oops! Something went wrong. Please try again.
                </p>
            )}
        </section>
    )
}

export default ContactUs
