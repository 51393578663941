// AboutSection.js
import React from "react"

function AboutSection() {
    return (
        <section id="about" className="py-16 bg-gray-50 text-center px-4">
            <h2 className="text-3xl font-bold mb-6">About Codified</h2>
            <p className="max-w-4xl mx-auto text-lg leading-relaxed">
                At Codified, we specialise in optimising the entire customer
                journey by leveraging industry-leading technology stacks, with a
                focus on AI and Machine Learning, and custom software solutions
                tailored to your unique needs. With over 15 years of customer
                service experience and custom software development, we are
                committed to driving customer experiences that fuel your
                business growth. It’s one thing to innovate, it’s the execution
                and drive that sets us apart.
            </p>
        </section>
    )
}

export default AboutSection
