// src/OurPartners.js
import React from "react"
import awsLogo from "./assets/aws-logo.svg" // Update with your image paths
import infobipLogo from "./assets/infobip-logo.svg" // Update with your image paths
import orangePillLogo from "./assets/orangepill-logo.webp" // Update with your image paths

function OurPartners() {
    return (
        <section id="partners" className="py-16 bg-neutralLight text-center">
            <h2 className="text-3xl font-bold text-white mb-10 px-4">
                Our Partners
            </h2>
            <div className="px-20 lg:px-4" >
                <div className="max-w-4xl mx-auto bg-white rounded-md shadow-md p-6">
                    <div className="flex flex-col md:flex-row items-center justify-around">
                        <div className="my-4">
                            <img
                                src={awsLogo}
                                alt="AWS Logo"
                                className="mx-auto h-16 transform hover:scale-105 transition duration-300"
                            />
                        </div>
                        <div className="my-4">
                            <img
                                src={infobipLogo}
                                alt="Infobip Logo"
                                className="mx-auto h-16 transform hover:scale-105 transition duration-300"
                            />
                        </div>
                        <div className="my-4">
                            <img
                                src={orangePillLogo}
                                alt="OrangePill Logo"
                                className="mx-auto h-8 transform hover:scale-105 transition duration-300"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
