// WhyChooseUs.js
import React from 'react';

function WhyChooseUs() {
  return (
    <section id="why-us" className="py-16 bg-gray-50 text-center px-4">
      <h2 className="text-3xl font-bold mb-10">Why Choose Us</h2>
      <ul className="max-w-4xl mx-auto text-left space-y-6 text-lg">
        <li>
          <strong className="text-primary">End-to-End Customer Journey Management:</strong> We support the entire customer journey, ensuring satisfaction at every touchpoint.
        </li>
        <li>
          <strong className="text-primary">AI and Machine Learning:</strong> Real-time predictions and actions based on behavioural science enhance customer engagement.
        </li>
        <li>
          <strong className="text-primary">Custom Software Solutions:</strong> Tailored integrations within the AWS environment, focusing on your unique needs.
        </li>
      </ul>
    </section>
  );
}

export default WhyChooseUs;
