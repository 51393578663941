// App.js
import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ServicesSection from './ServicesSection';
import WhyChooseUs from './WhyChooseUs';
import ContactUs from './ContactUs';
import Footer from './Footer';
import AnimatedBackground from './AnimatedBackground';
import OurPartners from './OurPartners';

function App() {
  return (
    <div className="relative overflow-hidden">
      <AnimatedBackground />
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Optional Overlay */}
      <div className="relative z-10">
        <Header />
        <HeroSection />
        <AboutSection />
        <ServicesSection />
        <WhyChooseUs />
        <OurPartners />
        <ContactUs />
        <Footer />
      </div>
    </div>
  );
}

export default App;
