// HeroSection.js
import React from "react"

function HeroSection() {
    return (
        <section className="relative bg-cover bg-center text-white py-32">
            <div className="relative z-10 max-w-3xl mx-auto text-center px-4">
                <h1 className="text-4xl md:text-1xl font-bold mb-4">
                    Elevating{" "}
                    <span>
                        <span className="text-secondary">[</span>
                        customer experience
                        <span className="text-secondary">]</span>
                    </span>{" "}
                    through innovation and automation
                </h1>
                {/* <p className="text-xl md:text-2xl mb-8">
                    Enhance experiences with chatbots, gamification, and
                    seamless system integration.
                </p>
                <a
                    href="#contact"
                    className="inline-block bg-secondary hover:bg-pink-600 text-white font-semibold py-3 px-8 rounded-full transition duration-300"
                >
                    Get Started
                </a> */}
            </div>
        </section>
    )
}

export default HeroSection
