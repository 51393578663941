// Footer.js
import React from "react"

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="bg-primary text-white py-8">
            <div className="max-w-4xl mx-auto text-center px-4">
                {/* <div className="mb-4">
                    <a href="#privacy" className="mx-2 hover:underline">
                        Privacy Policy
                    </a>{" "}
                    |
                    <a href="#terms" className="mx-2 hover:underline">
                        Terms of Service
                    </a>{" "}
                    |
                    <a href="#careers" className="mx-2 hover:underline">
                        Careers
                    </a>
                </div> */}
                <div className="mb-4">
                    <a
                        href="https://www.linkedin.com/company/codified-development/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mx-2 hover:underline"
                    >
                        LinkedIn
                    </a>
                    <a
                        href="https://www.facebook.com/profile.php?id=100084426180924"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mx-2 hover:underline"
                    >
                        Facebook
                    </a>
                </div>
                <p>&copy; {currentYear} Codified Development. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
