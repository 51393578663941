import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATiv_WtnrHR-9DwZ-yjoTExyys2C42i3o",
  authDomain: "codified-website.firebaseapp.com",
  projectId: "codified-website",
  storageBucket: "codified-website.appspot.com",
  messagingSenderId: "860659917270",
  appId: "1:860659917270:web:d96eff18cb294dd77d8477",
  measurementId: "G-6JQH0NQQM8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// adds google analytics
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
